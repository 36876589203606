* {
    box-sizing: border-box;
}

body {
  @apply dark:bg-gray-800 ;
}

.meImg {
  mix-blend-mode: overlay;
  object-fit: cover;
}

