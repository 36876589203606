.cardContainer {
    @apply p-4  
    flex  
    items-center 
    content-center
}

.imgme {
   /* mix-blend-mode :overlay;  multiply overlay hard light*/
   @apply mix-blend-lighten dark:md:mix-blend-overlay md:mix-blend-saturation
}

.carrier {
    color : red ;
    width : max-content;
    letter-spacing: 0.1em;
    position: relative;
}


.carrier::after {
    content: "";
    position: absolute;
    left: -100%;
    width: 100%;
    height: 100%;
    border-right : 2px solid red;
    animation: slide 5s steps(var(--steps)) infinite;
    @apply bg-gray-700/30;
}

@keyframes slide {
    from {
        left: -100%;
    }
    to {
        left: 0;
    }
}



.email {
    transition: all 0.2s ease-in-out;
}

.email:hover {
    @apply text-purple-700  ;
    transform: scale(1.05);
}

.email:hover svg {
    @apply fill-purple-700  ;
    transform: scale(1.05);
}



.ball {
    @apply bg-red-500 w-4 h-4 rounded-full  ; 
    animation-name: bounce ;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    
}

@keyframes bounce {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-40px);
    }
    
}